import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@c/icons';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

type PaginatorProps = {
  currentPage: number;
  totalPages: number;
  scroll?: boolean;
  setIsFetching?: ((isFetching: boolean) => void) | undefined;
};

const Paginator = ({
  totalPages,
  currentPage,
  scroll,
  setIsFetching,
}: PaginatorProps) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const getPageHref = useCallback(
    (direction: 'first' | 'last' | 'previous' | 'next' | number) => {
      let page;
      if (direction === 'first') {
        page = 1;
      } else if (direction === 'last') {
        page = totalPages;
      } else if (direction === 'previous') {
        page = currentPage - 1;
      } else if (direction === 'next') {
        page = currentPage + 1;
      } else {
        page = direction;
      }

      return {
        pathname: pathname?.split('?')[0],
        query: {
          page,
          ...(searchParams?.get('keyword')
            ? { keyword: searchParams?.get('keyword') }
            : {}),
          ...(searchParams?.get('shipTo')
            ? { shipTo: searchParams?.get('shipTo') }
            : {}),
          ...(searchParams?.get('shipFrom')
            ? { shipFrom: searchParams?.get('shipFrom') }
            : {}),
          ...(searchParams?.get('maxPrice')
            ? { maxPrice: searchParams?.get('maxPrice') }
            : {}),
          ...(searchParams?.get('minPrice')
            ? { minPrice: searchParams?.get('minPrice') }
            : {}),
          ...(searchParams?.get('sort')
            ? { sort: searchParams?.get('sort') }
            : {}),
          ...(searchParams?.get('sellerType')
            ? { sellerType: searchParams?.get('sellerType') }
            : {}),
          ...(searchParams?.get('view')
            ? { view: searchParams?.get('view') }
            : {}),
          ...(searchParams?.get('soldDate') ? { soldDate: 'true' } : {}),
          ...(searchParams?.get('tab')
            ? { tab: searchParams?.get('tab') }
            : {}),
          ...(searchParams?.get('limit')
            ? { limit: searchParams?.get('limit') }
            : {}),
          ...(searchParams?.get('ride_type')
            ? { ride_type: searchParams?.get('ride_type') }
            : {}),
          ...(searchParams?.get('rideTypes')
            ? { rideTypes: searchParams?.get('rideTypes') }
            : {}),
          ...(searchParams?.get('category')
            ? { category: searchParams?.get('category') }
            : {}),
          ...(searchParams?.get('category1')
            ? { category1: searchParams?.get('category1') }
            : {}),
          ...(searchParams?.get('category2')
            ? { category2: searchParams?.get('category2') }
            : {}),
          ...(searchParams?.get('category3')
            ? { category3: searchParams?.get('category3') }
            : {}),
          ...(searchParams?.get('brands')
            ? { brand: searchParams?.get('brands') }
            : {}),
          ...(searchParams?.get('size')
            ? { size: searchParams?.get('size') }
            : {}),
        },
      };
    },
    [currentPage, pathname, searchParams, totalPages]
  );

  const getPageButtons = () => {
    if (totalPages <= 5 + 2) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage < 5) {
      return [1, 2, 3, 4, 5, '...', totalPages];
    }

    if (currentPage === 5) {
      return [1, '...', 4, 5, 6, '...', totalPages];
    }

    if (currentPage < totalPages - 4) {
      return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPages,
      ];
    }

    if (currentPage > totalPages - 4) {
      return [
        1,
        '...',
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
    return [
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      totalPages,
    ];
  };

  return (
    <>
      <div className="flex w-full items-center justify-center gap-x-4">
        {currentPage > 1 ? (
          <>
            <Link
              scroll={false}
              aria-label={'First page'}
              href={getPageHref('first')}
              onClick={() => setIsFetching?.(true)}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronDoubleLeftIcon />
            </Link>
            <Link
              aria-label={'Previous page'}
              scroll={false}
              href={getPageHref('previous')}
              onClick={() => setIsFetching?.(true)}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronLeftIcon />
            </Link>
          </>
        ) : (
          <>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronDoubleLeftIcon />
            </span>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronLeftIcon />
            </span>
          </>
        )}

        {getPageButtons().map((page, index) => {
          if (page === '...') {
            return (
              <span
                key={'elipsis-' + index}
                className="flex h-touch w-touch items-center justify-center"
              >
                ...
              </span>
            );
          }
          return (
            <Link
              aria-label={`Page ${page}`}
              key={page}
              scroll={false}
              href={getPageHref(Number(page))}
              onClick={() => setIsFetching?.(true)}
              className={`flex h-touch w-touch cursor-pointer items-center justify-center rounded-full px-4 font-semibold transition-colors ${
                currentPage === page
                  ? 'bg-brand-secondary text-white'
                  : 'bg-white text-black'
              }`}
            >
              <span>{page}</span>
            </Link>
          );
        })}
        {currentPage < totalPages ? (
          <>
            <Link
              aria-label={'Next page'}
              scroll={false}
              href={getPageHref('next')}
              onClick={() => setIsFetching?.(true)}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronRightIcon />
            </Link>
            <Link
              aria-label={'Last page'}
              scroll={false}
              href={getPageHref('last')}
              onClick={() => setIsFetching?.(true)}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronDoubleRightIcon />
            </Link>
          </>
        ) : (
          <>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronRightIcon />
            </span>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronDoubleRightIcon />
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default Paginator;

export const PaginatorClient = ({
  totalPages,
  currentPage,
  onPageChange,
  setIsFetching,
}: PaginatorProps & { onPageChange: (n: number) => void }) => {
  const getPageNumber = (
    direction: 'first' | 'last' | 'previous' | 'next' | number
  ) => {
    let page;
    if (direction === 'first') {
      page = 1;
    } else if (direction === 'last') {
      page = totalPages;
    } else if (direction === 'previous') {
      page = currentPage - 1;
    } else if (direction === 'next') {
      page = currentPage + 1;
    } else {
      page = direction;
    }
    return page;
  };

  const getPageButtons = () => {
    if (totalPages <= 5 + 2) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage < 5) {
      return [1, 2, 3, 4, 5, '...', totalPages];
    }

    if (currentPage === 5) {
      return [1, '...', 4, 5, 6, '...', totalPages];
    }

    if (currentPage < totalPages - 4) {
      return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPages,
      ];
    }

    if (currentPage > totalPages - 4) {
      return [
        1,
        '...',
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
    return [
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      totalPages,
    ];
  };

  const handlePageChange = (page: number) => {
    if (setIsFetching) setIsFetching(true);
    onPageChange(page);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        {currentPage > 1 ? (
          <>
            <button
              onClick={() => handlePageChange(getPageNumber('first'))}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronDoubleLeftIcon />
            </button>

            <button
              onClick={() => handlePageChange(getPageNumber('previous'))}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronLeftIcon />
            </button>
          </>
        ) : (
          <>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronDoubleLeftIcon />
            </span>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronLeftIcon />
            </span>
          </>
        )}

        {getPageButtons().map((page, index) => {
          if (page === '...') {
            return (
              <span
                key={'elipsis-' + index}
                className="flex h-touch w-touch items-center justify-center"
              >
                ...
              </span>
            );
          }
          return (
            <button
              key={page}
              onClick={() => handlePageChange(getPageNumber(Number(page)))}
              className={`flex h-touch w-touch items-center justify-center rounded-full font-semibold transition-colors ${
                currentPage === page
                  ? 'bg-brand-secondary text-white'
                  : 'bg-white text-black'
              }
              `}
            >
              <span>{page}</span>
            </button>
          );
        })}
        {currentPage < totalPages ? (
          <>
            <button
              onClick={() => handlePageChange(getPageNumber('next'))}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronRightIcon />
            </button>
            <button
              onClick={() => handlePageChange(getPageNumber('last'))}
              className="flex h-touch w-touch items-center justify-center rounded-full"
            >
              <ChevronDoubleRightIcon />
            </button>
          </>
        ) : (
          <>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronRightIcon />
            </span>
            <span className="flex h-touch w-touch items-center justify-center rounded-full text-gray-300">
              <ChevronDoubleRightIcon />
            </span>
          </>
        )}
      </div>
    </>
  );
};
